const paths = [
    {
        path: '/project-execution',
        name: 'ProjectExecution',
        title: 'PROJECT_EXECUTION',
        icon: 'fa fa-building',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecution.vue'),
    },
    {
        path: '/project-execution-activity',
        name: 'ProjectExecutionActivity',
        title: 'ACTIVITY',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionActivity.vue'),
    },
    {
        path: '/project-execution-activity-team-allocation',
        name: 'ProjectExecutionActivityTeamAllocation',
        title: 'TEAMS_AND_EMPLOYEE_ALLOCATION',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionActivityTeamAllocation.vue'),
    },
    {
        path: '/project-execution-issue',
        name: 'ProjectExecutionIssue',
        title: 'ISSUE',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionIssue.vue'),
    },
    {
        path: '/project-execution-report',
        name: 'ProjectExecutionReport',
        title: 'EXECUTION_REPORT',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionReport.vue'),
    },
    {
        path: '/project-execution-report-generate-wizard',
        name: 'ProjectExecutionReportGenerateWizard',
        title: 'PROJECT_EXECUTION',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionReportGenerateWizard.vue'),
    },
    {
        path: '/execution-report-form',
        name: 'ExecutionReportForm',
        title: 'EXECUTION_REPORT_FORM',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ExecutionReportForm.vue'),
    },
    {
        path: '/execution-report-activity-change',
        name: 'ProjectExecutionReportActivityChange',
        title: 'INCLUDE_EXCLUDE_ACTIVITY',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionReportActivityChange.vue'),
    },
    {
        path: '/execution-report-allocation',
        name: 'ProjectExecutionReportAllocation',
        title: 'ACTIVITY_ALLOCATION',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionReportAllocation.vue'),
    },
    {
        path: '/project-execution-activity-photographic-record',
        name: 'ProjectExecutionActivityPhotographicRecord',
        title: 'PHOTOGRAPHIC_RECORD',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectExecutionActivityPhotographicRecord.vue'),
    },
    {
        path: '/project-images-config-export-wizard',
        name: 'ProjectImagesConfigExportWizard',
        component: () => import(/* webpackChunkName: "projectExecution" */ /* webpackMode: "lazy" */ '@/pages/project/projectExecution/ProjectImagesConfigExportWizard.vue'),
    },
];

export default paths;

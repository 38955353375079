import { StaticEntity } from '@inputs/entity.ts';

import YesNo from '@inputs/static/provider/YesNo';
import YesNoAll from '@inputs/static/provider/YesNoAll';
import MaritalStatus from '@inputs/static/provider/MaritalStatus';
import SituationContributor from '@inputs/static/provider/SituationContributor';
import CategoryCNH from '@inputs/static/provider/CategoryCNH';
import HourMounth from '@inputs/static/provider/HourMounth';
import Roles from '@inputs/static/provider/Roles';
import TypeFinancialOperation from '@inputs/static/provider/TypeFinancialOperation';
import ActivityBranch from '@inputs/static/provider/ActivityBranch';
import OrderServiceAssetsOrdination from '@inputs/static/provider/OrderServiceAssetsOrdination';
import Groupment from '@inputs/static/provider/Groupment';
import TimePrompt from '@inputs/static/provider/TimePrompt';
import AttributeValueComparators from '@inputs/static/provider/AttributeValueComparators';
import TypeStructure from '@inputs/static/provider/TypeStructure';
import OrderItemOrdination from '@inputs/static/provider/OrderItemOrdination';
import Operation from '@inputs/static/provider/Operation';
import OperationDetailed from '@inputs/static/provider/OperationDetailed';
import Entity from '@inputs/static/provider/Entity';
import OrderPhysicalStockListOrdination from '@inputs/static/provider/OrderPhysicalStockListOrdination';
import TeamContributorType from '@inputs/static/provider/TeamContributorType';
import MaterialDispensationType from '@inputs/static/provider/MaterialDispensationType';
import RunnerResult from '@inputs/static/provider/RunnerResult';
import AuxiliaryStockMovementTypes from '@inputs/static/provider/auxiliaryStockMovementTypes';
import FinancialScheduleDuplicateAlertStages from '@inputs/static/provider/FinancialScheduleDuplicateAlertStages';
import StructureType from '@inputs/static/provider/StructureType';
import FinancialCategoryOperationType from '@inputs/static/provider/FinancialCategoryOperationType';
import TermTreatment from '@inputs/static/provider/TermTreatment';
import InterventionOperation from '@inputs/static/provider/InterventionOperation.json';
import MonitorPeriodicity from '@inputs/static/provider/MonitorPeriodicity';
import MonitorTicketType from '@inputs/static/provider/MonitorTicketType';
import Gender from '@inputs/static/provider/Gender';
import ApprovalStatus from '@inputs/static/provider/ApprovalStatus.json';
import DonePendingAll from '@inputs/static/provider/DonePendingAll.json';
import ExportType from '@inputs/static/provider/ExportType.json';
import ScheduleType from '@inputs/static/provider/ScheduleType.json';
import StatusServiceOrderTask from '@inputs/static/provider/StatusServiceOrderTask.json';
import TicketStatusType from '@inputs/static/provider/TicketStatusType.json';
import LastPurchase from '@inputs/static/provider/LastPurchase.json';
import ScheduleEntity from '@inputs/static/provider/ScheduleEntity.json';
import CoordinateSystem from '@/util/inputs/static/provider/CoordinateSystem';
import Datum from '@/util/inputs/static/provider/Datum';
import StructureDesignType from '@/util/inputs/static/provider/StructureDesignType';
import LatitudeDirection from '@/util/inputs/static/provider/LatitudeDirection';
import LongitudeDirection from '@/util/inputs/static/provider/LongitudeDirection';
import MovementAllocationStatus from '@inputs/static/provider/MovementAllocationStatus.json';
import PendingTicketDataType from '@inputs/static/provider/PendingTicketDataType.json';
import PendingTicketsOrderBy from '@inputs/static/provider/PendingTicketsOrderBy.json';
import PendingTicketMinimumStock from '@inputs/static/provider/PendingTicketMinimumStock.json';
import Weekdays from '@inputs/static/provider/Weekdays.json';
import OrderByProjectMaterial from '@inputs/static/provider/OrderByProjectMaterial.json';
import ServiceCompositionOperation from '@/util/inputs/static/provider/ServiceCompositionOperation';
import DetailedSummedUpReport from '@inputs/static/provider/DetailedSummedUpReport.json';
import FinancialEventCategory from '@inputs/static/provider/FinancialEventCategory.json';
import FinancialEventStatusPay from '@inputs/static/provider/FinancialEventStatusPay.json';
import FinancialEventStatusReceive from '@inputs/static/provider/FinancialEventStatusReceive.json';
import FinancialEventCategorization from '@inputs/static/provider/FinancialEventCategorization.json';
import CreditOrigin from '@inputs/static/provider/CreditOrigin.json';
import HolidayHandlingModality from '@inputs/static/provider/HolidayHandlingModality';
import NaturalLegalPerson from '@inputs/static/provider/NaturalLegalPerson.json';
import FreightType from '@inputs/static/provider/FreightType';
import IPI from '@inputs/static/provider/IPI';
import ICMS from '@inputs/static/provider/ICMS';
import ViewIssueMapColorBy from '@inputs/static/provider/ViewIssueMapColorBy';
import DecimalPrecision from '@inputs/static/provider/DecimalPrecision';
import PurchaseOrderPendency from '@inputs/static/provider/PurchaseOrderPendency';
import ItemsAppliedToApply from '@inputs/static/provider/ItemsAppliedToApply.json';
import TypeOfApplication from '@inputs/static/provider/TypeOfApplication.json';
import DateReferenceEnergyConsumptionComparison from '@inputs/static/provider/DateReferenceEnergyConsumptionComparison.json';
import GreaterThanEqualsOperator from '@inputs/static/provider/GreaterThanEqualsOperator.json';
import FinancialQueryType from '@/util/inputs/static/provider/FinancialQueryType.json';
import AccomplishedPendentReceiveFinancial from '@/util/inputs/static/provider/AccomplishedPendentReceiveFinancial.json';
import CalendarSchedule from '@/util/inputs/static/provider/CalendarSchedule.json';
import CreditOrDebit from '@inputs/static/provider/CreditOrDebit.json';
import CreditOrDebitAll from '@inputs/static/provider/CreditOrDebitAll.json';
import TransactionType from '@inputs/static/provider/TransactionType.json';
import AssetTypeMeasure from '@inputs/static/provider/AssetTypeMeasure.json';
import AssetTypePlaqueType from '@inputs/static/provider/AssetTypePlaqueType.json';
import QueryType from '@inputs/static/provider/QueryType.json';
import BudgetProviderQueryOptions from '@inputs/static/provider/BudgetProviderQueryOptions.json';
import MaintenanceRegardingDeadlineGroupingType from '@inputs/static/provider/MaintenanceRegardingDeadlineGroupingType.json';
import ChartType from '@inputs/static/provider/ChartType.json';
import ValidAssets from '@inputs/static/provider/ValidAssets.json';
import OriginMaintenance from '@inputs/static/provider/OriginMaintenance.json';
import PolygonFunction from '@inputs/static/provider/PolygonFunction.json';
import PoiType from '@inputs/static/provider/PoiType.json';
import PendingFinancialAllocationMade from '@inputs/static/provider/PendingFinancialAllocationMade.json';
import InvoiceStatus from '@inputs/static/provider/InvoiceStatus.json';
import NonePartialTotal from '@inputs/static/provider/NonePartialTotal.json';
import SamplesOrderBy from '@inputs/static/provider/SamplesOrderBy.json';
import OperationWithoutLimitedOptions from '@inputs/static/provider/OperationWithoutLimitedOptions';
import StageStatus from '@inputs/static/provider/StageStatus';
import FillAuthorization from '@inputs/static/provider/FillAuthorization.json';
import SubdivisionsResponsible from '@inputs/static/provider/SubdivisionsResponsible.json';
import SubdivisionsStatus from '@inputs/static/provider/SubdivisionsStatus.json';
import SubdivisionsEntity from '@inputs/static/provider/SubdivisionsEntity.json';
import GlobalUnitary from '@inputs/static/provider/GlobalUnitary.json';
import MaterialState from '@inputs/static/provider/MaterialState.json';
import LoanDevolutionStatus from '@inputs/static/provider/LoanDevolutionStatus.json';
import PurchaseDisplayType from '@inputs/static/provider/PurchaseDisplayType';
import CashFlowPeriodicity from '@inputs/static/provider/CashFlowPeriodicity.json';
import CoordinateType from '@/util/inputs/static/provider/CoordinateType';
import CoordinateTypeById from '@/util/inputs/static/provider/CoordinateTypeById';
import AssetToUpdate from '@/util/inputs/static/provider/AssetToUpdate';
import ScheduleOrTransaction from '@/util/inputs/static/provider/ScheduleOrTransaction';
import TypesOfCommands from '@inputs/static/provider/TypesOfCommands.json';
import ComponentType from '@inputs/static/provider/ComponentType.json';
import MaintenanceType from '@inputs/static/provider/MaintenanceType.json';
import MaintenanceTypeAll from '@inputs/static/provider/MaintenanceTypeAll.json';
import ThematicMapEntity from '@inputs/static/provider/ThematicMapEntity.json';
import ThematicMapView from '@inputs/static/provider/ThematicMapView.json';
import Locality from '@inputs/static/provider/Locality.json';
import EvaluationType from '@inputs/static/provider/EvaluationType.json';
import TaskStatus from '@inputs/static/provider/TaskStatus.json';
import ProcessStatus from '@inputs/static/provider/ProcessStatus.json';
import Parser from '@inputs/static/provider/Parser.json';
import ActivityExecution from '@inputs/static/provider/ActivityExecution.json';
import LocaleType from '@inputs/static/provider/LocaleType.json';
import ServiceOrderAutomatic from '@/util/inputs/static/provider/ServiceOrderAutomatic.json';
import PeriodTypes from '@/util/inputs/static/provider/PeriodTypes.json';
import AssetPhotosImportMode from '@/util/inputs/static/provider/AssetPhotosImportMode.json';
import SolidWasteDocumentType from '@/util/inputs/static/provider/SolidWasteDocumentType.json';
import SolidWasteStatusDocument from '@/util/inputs/static/provider/SolidWasteStatusDocument.json';
import SolidWastePendingType from '@/util/inputs/static/provider/SolidWastePendingType.json';
import UpdateType from '@/util/inputs/static/provider/UpdateType.json';
import DeadlineStatus from '@/util/inputs/static/provider/DeadlineStatus.json';
import Modernization from '@/util/inputs/static/provider/Modernization.json';
import Calculation from '@/util/inputs/static/provider/Calculation.json';
import PaperSize from '@/util/inputs/static/provider/PaperSize.json';
import Schedule from '@/util/inputs/static/provider/Schedules.json';
import MandatoryOptionalNoControl from '@/util/inputs/static/provider/MandatoryOptionalNoControl.json';
import MandatoryOptional from '@/util/inputs/static/provider/MandatoryOptional.json';
import AutoManual from '@/util/inputs/static/provider/AutoManual.json';
import ClientOrOwn from '@/util/inputs/static/provider/ClientOrOwn.json';
import TypeVersionApp from '@inputs/static/provider/TypeVersionApp';
import MaintenanceQueryType from '@inputs/static/provider/MaintenanceQueryType';
import InternalExternal from '@/util/inputs/static/provider/InternalExternal.json';
import ItemAndFamilyQrcodeIdentification from '@inputs/static/provider/ItemAndFamilyQrcodeIdentification';
import QRCodeFillsAttributes from '@inputs/static/provider/QRCodeFillsAttributes';
import ItemEntityType from '@inputs/static/provider/ItemEntityType';
import ClientStatus from '@inputs/static/provider/ClientStatus';
import Hemisphere from '@/util/inputs/static/provider/Hemisphere.json';
import AssetGroupingTypes from '@/util/inputs/static/provider/AssetGroupingTypes.json';
import OriginType from '@/util/inputs/static/provider/OriginType.json';
import TrackingIntegrationCodes from '@inputs/static/provider/TrackingIntegrationCodes';
import AutomaticActionReopenConfig from '@/util/inputs/static/provider/AutomaticActionReopenConfig.json';
import AutomaticActionReopenConfigDefinition from '@/util/inputs/static/provider/AutomaticActionReopenConfigDefinition.json';
import AuthorizeReopenStatus from '@/util/inputs/static/provider/AuthorizeReopenStatus.json';
import {
    RemainingTimeType, GroupingType, IssueTicketStatus, MaintenanceOrigin, TicketType,
} from '@/pages/pendingFulfilledIssuesTickets/services/providers';
import CreditOrDebitoOrZero from '@/util/inputs/static/provider/CreditOrDebitOrZero.json';
import ReprogrammedIssueGroupingRule from '@/util/inputs/static/provider/ReprogrammedIssueGroupingRule.json';
import Breakpoints from '@/util/inputs/static/provider/Breakpoints.json';
import ReversalAction from '@/util/inputs/static/provider/ReversalAction.json';
import NCallStatus from '@/util/inputs/static/provider/NCallStatus.json';
import WarrantyType from '@/util/inputs/static/provider/WarrantyType.json';
import ProcessModels from '@/util/inputs/static/provider/ProcessModels.json';
import IssuePanelOnTime from '@/util/inputs/static/provider/IssuePanelOnTime.json';
import Domains from '@/util/inputs/static/provider/Domains.json';
import ProcessIdentificationType from '@/util/inputs/static/provider/ProcessIdentificationType.json';
import EmployeeAlocationFilter from '@/util/inputs/static/provider/EmployeeAlocationFilter.json';
import ReopenedIssueGroupingRule from '@/util/inputs/static/provider/ReopenedIssueGroupingRule.json';

const staticProvider = {
    issueTicketStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: IssueTicketStatus }),
    groupingType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: GroupingType }),
    maintenanceOrigin: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaintenanceOrigin }),
    remainingTimeType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: RemainingTimeType }),
    ticketType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TicketType }),
    trackingIntegrationCodes: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TrackingIntegrationCodes }),
    yesNo: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: YesNo }),
    yesNoAll: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: YesNoAll }),
    maritalStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaritalStatus }),
    situationContributor: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SituationContributor }),
    categoryCNH: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CategoryCNH }),
    hourMounth: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: HourMounth }),
    roles: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Roles }),
    typeFinancialOperation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypeFinancialOperation }),
    activityBranch: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ActivityBranch }),
    orderServiceAssetsOrdination: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OrderServiceAssetsOrdination }),
    groupment: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Groupment }),
    timePrompt: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TimePrompt }),
    attributeValueComparators: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AttributeValueComparators }),
    typeStructure: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypeStructure }),
    orderItemOrdination: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OrderItemOrdination }),
    operation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Operation }),
    operationDetailed: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OperationDetailed }),
    entity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Entity }),
    orderPhysicalStockListOrdination: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OrderPhysicalStockListOrdination }),
    teamContributorType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TeamContributorType }),
    materialDispensationType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaterialDispensationType }),
    runnerResult: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: RunnerResult }),
    auxiliaryStockMovementTypes: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AuxiliaryStockMovementTypes }),
    financialScheduleDuplicateAlertStages: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialScheduleDuplicateAlertStages }),
    structureType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: StructureType }),
    financialCategoryOperationType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialCategoryOperationType }),
    termTreatment: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TermTreatment }),
    interventionOperation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: InterventionOperation }),
    monitorPeriodicity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MonitorPeriodicity }),
    monitorTicketType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MonitorTicketType }),
    gender: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Gender }),
    approvalStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ApprovalStatus }),
    donePendingAll: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DonePendingAll }),
    exportType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ExportType }),
    scheduleType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ScheduleType }),
    statusServiceOrderTask: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: StatusServiceOrderTask }),
    ticketStatusType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TicketStatusType }),
    lastPurchase: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: LastPurchase }),
    scheduleEntity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ScheduleEntity }),
    coordinateSystem: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CoordinateSystem }),
    datum: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Datum }),
    structureDesignType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: StructureDesignType }),
    latitudeDirection: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: LatitudeDirection }),
    longitudeDirection: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: LongitudeDirection }),
    movementAllocationStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MovementAllocationStatus }),
    pendingTicketDataType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PendingTicketDataType }),
    pendingTicketsOrderBy: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PendingTicketsOrderBy }),
    pendingTicketMinimumStock: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PendingTicketMinimumStock }),
    weekdays: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Weekdays }),
    orderByProjectMaterial: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OrderByProjectMaterial }),
    serviceCompositionOperation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ServiceCompositionOperation }),
    detailedSummedUpReport: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DetailedSummedUpReport }),
    financialEventCategory: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialEventCategory }),
    financialEventStatusPay: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialEventStatusPay }),
    financialEventStatusReceive: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialEventStatusReceive }),
    financialEventCategorization: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialEventCategorization }),
    creditOrigin: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CreditOrigin }),
    holidayHandlingModality: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: HolidayHandlingModality }),
    naturalLegalPerson: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: NaturalLegalPerson }),
    freightTypeStatic: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FreightType }),
    ipi: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: IPI }),
    icms: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ICMS }),
    viewIssueMapColorBy: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ViewIssueMapColorBy }),
    decimalPrecision: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DecimalPrecision }),
    purchaseOrderPendency: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PurchaseOrderPendency }),
    itemsAppliedToApply: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ItemsAppliedToApply }),
    typeOfApplication: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypeOfApplication }),
    dateReferenceEnergyConsumptionComparison: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DateReferenceEnergyConsumptionComparison }),
    greaterThanEqualsOperator: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: GreaterThanEqualsOperator }),
    financialQueryType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FinancialQueryType }),
    accomplishedPendentReceiveFinancial: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AccomplishedPendentReceiveFinancial }),
    calendarSchedule: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CalendarSchedule }),
    creditOrDebit: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CreditOrDebit }),
    creditOrDebitOrZero: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CreditOrDebitoOrZero }),
    creditOrDebitAll: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CreditOrDebitAll }),
    transactionType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TransactionType }),
    assetTypeMeasure: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssetTypeMeasure }),
    assetTypePlaqueType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssetTypePlaqueType }),
    queryType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: QueryType }),
    budgetProviderQueryOptions: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: BudgetProviderQueryOptions }),
    maintenanceRegardingDeadlineGroupingType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaintenanceRegardingDeadlineGroupingType }),
    chartType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ChartType }),
    validAssets: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ValidAssets }),
    originMaintenance: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OriginMaintenance }),
    polygonFunction: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PolygonFunction }),
    poiType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PoiType }),
    pendingFinancialAllocationMade: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PendingFinancialAllocationMade }),
    invoiceStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: InvoiceStatus }),
    nonePartialTotal: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: NonePartialTotal }),
    samplesOrderBy: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SamplesOrderBy }),
    operationWithoutLimitedOptions: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OperationWithoutLimitedOptions }),
    stageStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: StageStatus }),
    fillAuthorization: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: FillAuthorization }),
    subdivisionsResponsible: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SubdivisionsResponsible }),
    subdivisionsStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SubdivisionsStatus }),
    subdivisionsEntity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SubdivisionsEntity }),
    unitaryGlobal: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: GlobalUnitary }),
    loanDevolutionStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: LoanDevolutionStatus }),
    materialState: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaterialState }),
    purchaseDisplayType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PurchaseDisplayType }),
    cashFlowPeriodicity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CashFlowPeriodicity }),
    coordinateType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CoordinateType }),
    coordinateTypeId: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: CoordinateTypeById }),
    assetToUpdate: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssetToUpdate }),
    scheduleOrTransaction: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ScheduleOrTransaction }),
    typesOfCommands: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypesOfCommands }),
    componentType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ComponentType }),
    maintenanceType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaintenanceType }),
    maintenanceTypeAll: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaintenanceTypeAll }),
    thematicMapEntity: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ThematicMapEntity }),
    thematicMapView: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ThematicMapView }),
    locality: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Locality }),
    evaluationType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: EvaluationType }),
    taskStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TaskStatus }),
    processStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ProcessStatus }),
    parserUrl: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Parser }),
    activityExecution: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ActivityExecution }),
    localeType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: LocaleType }),
    serviceOrderAutomatic: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ServiceOrderAutomatic }),
    periodTypes: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: PeriodTypes }),
    assetPhotosImportMode: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssetPhotosImportMode }),
    solidWasteDocumentType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SolidWasteDocumentType }),
    solidWasteStatusDocument: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SolidWasteStatusDocument }),
    solidWastePendingType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: SolidWastePendingType }),
    updateType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: UpdateType }),
    deadlineStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: DeadlineStatus }),
    modernization: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Modernization }),
    calculation: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Calculation }),
    paperSize: new StaticEntity({ fieldNameId: 'SITUATION', descriptionField: 'PAPER', staticProvider: PaperSize }),
    schedule: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Schedule }),
    mandatoryOptionalNoControl: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MandatoryOptionalNoControl }),
    mandatoryOptional: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MandatoryOptional }),
    autoManual: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AutoManual }),
    clientOrOwn: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ClientOrOwn }),
    typeVersionApp: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: TypeVersionApp }),
    maintenanceQueryType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: MaintenanceQueryType }),
    itemAndFamilyQrcodeIdentification: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ItemAndFamilyQrcodeIdentification }),
    qrCodeFillsAttributes: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: QRCodeFillsAttributes }),
    itemEntityType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ItemEntityType }),
    clientStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ClientStatus }),
    hemisphere: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Hemisphere }),
    importAssetGroupingTypes: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AssetGroupingTypes }),
    originType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: OriginType }),
    automaticActionReopenConfig: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AutomaticActionReopenConfig }),
    automaticActionReopenConfigDefinition: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AutomaticActionReopenConfigDefinition }),
    authorizeReopenStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: AuthorizeReopenStatus }),
    reprogrammedIssueGroupingRule: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ReprogrammedIssueGroupingRule }),
    breakpoints: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Breakpoints }),
    reversalAction: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ReversalAction }),
    nCallStatus: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: NCallStatus }),
    internalExternal: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: InternalExternal }),
    warrantyType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: WarrantyType }),
    processModels: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ProcessModels }),
    issuePanelOnTime: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: IssuePanelOnTime }),
    domains: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: Domains }),
    processIdentificationType: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ProcessIdentificationType }),
    employeeAlocationFilter: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: EmployeeAlocationFilter }),
    reopenedIssueGroupingRule: new StaticEntity({ fieldNameId: 'VALUE', descriptionField: 'KEY', staticProvider: ReopenedIssueGroupingRule }),
};

export default staticProvider;

import alert from './alert/alert';
import notification from './notification/notification';
import theme from './theme/theme';
import request from './request/request';
import getArray from './util/helper/getArray';
import getObject from './util/helper/getObject';
import merge from './util/helper/merge';
import clone from './util/helper/clone';
import cloneDeep from './util/helper/cloneDeep';
import isEmpty from './util/helper/isEmpty';
import isMatch from './util/helper/isMatch';
import isEqual from './util/helper/isEqual';
import debounce from './util/helper/debounce';
import camelCase from './util/helper/camelCase';
import orderBy from './util/helper/orderby';
import groupBy from './util/helper/groupby';
import differenceWith from './util/helper/differenceWith';
import get from './util/helper/get';
import phoneRegion from './util/helper/phoneRegion';
import events from './util/events/events';
import image from './util/image/image';
import inputs from './util/inputs/inputs';
import i18n from '@/i18n';

const aura = {
    options: {},
    initializeOptions: function (options = {}, vue) {
        const aux = {};
        this.options = Object.assign({}, aux, options.aura || {});

        events.initializeOptions();
        image.initializeOptions(options.image || {});
        theme.initializeOptions(options.theme || {});
        notification.initializeOptions(options.notification || {});
        request.initializeOptions(options.request || {});
        alert.initializeOptions(options.alert || {}, vue);
        inputs.initializeOptions(options.inputs || {});
        phoneRegion.initializeOptions(options.phoneRegion || {});
        this.pivot = options.pivot || {};
    },
    events: events,
    inputs: inputs,
    pivot: {},
    /** Theme */
    getColor: function (value) {
        return theme.getColor(value);
    },
    getImage: function (value) {
        return theme.getImage(value);
    },

    /** Image */
    image: image,

    /** Util */
    getArray: function (value) {
        return getArray(value);
    },
    getObject: function (value) {
        return getObject(value);
    },
    merge: function (object, sources) {
        return merge(object, sources);
    },
    clone: function (object) {
        return clone(object);
    },
    cloneDeep: function (object) {
        return cloneDeep(object);
    },
    isEmpty: function (value) {
        return isEmpty(value);
    },
    isMatch: function (object, source) {
        return isMatch(object, source);
    },
    isEqual: function (object, source) {
        return isEqual(object, source);
    },
    camelCase: function (value) {
        return camelCase(value);
    },
    debounce: function (func, time) {
        return debounce(func, time);
    },
    orderBy: function (collection, iteratees, orders) {
        return orderBy(collection, iteratees, orders);
    },
    groupBy: function (collection, iteratees) {
        return groupBy(collection, iteratees);
    },
    differenceWith: function (array, values, comparator) {
        return differenceWith(array, values, comparator);
    },
    get: function (object, path, defaultValue) {
        return get(object, path, defaultValue);
    },

    /** Notification (Toastr) */
    showInfoMessage: function (message) {
        notification.infoMessage(message);
    },
    showSuccessMessage: function (message) {
        notification.successMessage(message);
    },
    showWarningMessage: function (message) {
        notification.warningMessage(message);
    },
    showErrorMessage: function (message) {
        notification.errorMessage(message);
    },
    removeMessages: function () {
        notification.remove();
    },
    clearMessages: function () {
        notification.clear();
    },

    /** Request */
    user: {},
    setUser: function (value) {
        this.user = value;
    },
    getUser: function () {
        return this.user;
    },
    getToken: function () {
        return localStorage.getItem('token');
    },
    param: function (key, value, prependCMD) {
        return request.param(key, value, prependCMD);
    },
    getHTTPUrl: function () {
        return request.getHTTPUrl();
    },
    setHTTPUrl: function (url) {
        request.setHTTPUrl(url);
    },
    handleResult: function (result, silent) {
        return request.handleResult(result, silent);
    },
    /**
     * CallCommand
     *
     * Uso padrão: callCommand(commandName, params, config, debugLog)
     * Debug: Uso padrão: callCommand(debugLog, commandName, params, config)
     * @returns promise
     */
    callCommand: function () {
        let commandName;
        let params;
        let config;
        let debug;
        let authorization;

        if (typeof arguments[0] === 'boolean') {
            commandName = arguments[1];
            params = arguments[2];
            config = arguments[3];
            debug = arguments[0];
            authorization = arguments[4];
        } else {
            commandName = arguments[0];
            params = arguments[1];
            config = arguments[2];
            debug = arguments[3];
            authorization = arguments[4];
        }

        return request.request(commandName, params || {}, config || {}, debug || false, authorization || {});
    },
    getPersonImage: function (id) {
        return request.request('ConsultarFotoPessoa', { ID_PESSOA: id }, { type: 'file' }).then(
            (resolve) => resolve,
        );
    },
    getErrors: function (resolve) {
        return getArray(() => resolve.RAIZ.MESSAGES.ERRORS);
    },
    debugMode: function () {
        return request.debugMode();
    },

    /** Sweet Alert */
    swal: function (title, text, type) {
        return alert.showAlert(title, text, type);
    },
    swalO: function (options, mergeDefaultOptions) {
        return alert.showAlertWithOptions(options, mergeDefaultOptions);
    },
    swalDelete: function () {
        return alert.showAlert(i18n.t('WARNING'), i18n.t('REALLY_WANT_DELETE_THIS_ACTION_CANNOT_REVERSED'));
    },
    swalUnlink: function () {
        return alert.showAlert(i18n.t('WARNING'), i18n.t('REALLY_WANT_UNLINK_THIS_ACTION_CANNOT_REVERSED'));
    },

    /* Phone Region */
    getPhoneRegion: function () {
        return phoneRegion.getPhoneRegion();
    },
};

export default aura;

export default {
    props: {
        /**
         * Selected items
         * @type {Array}
         * @default []
         */
        value: {
            type: Array,
            default: () => [],
        },
        /**
         * items
         * @type {Array}
         * @default []
         */
        items: {
            type: Array,
            default: () => [],
        },
        /**
         * Headers
         * @type {Array}
         * @default []
         */
        headers: {
            type: Array,
            default: () => []
        },
        /**
         * Items key
         * @type {String}
         * @default []
         */
        itemKey: {
            type: String,
            default: null,
        },
        _extendedHeaders: {
            type: Array,
            default: () => [],
        },
        _title: {
            type: String,
            default: undefined,
        },
        _command: {
            type: Object,
            default: function () {
                return {};
            },
        },
        _dense: {
            type: Boolean,
            default: true,
        },
        _hideDefaultFooter: {
            type: Boolean,
            default: true,
        },
        _hideFooter: {
            type: Boolean,
            default: false,
        },
        _hideSearchBar: {
            type: Boolean,
            default: false,
        },
        _filterDisabled: {
            type: Boolean,
            default: false,
        },
        search: {
            type: String,
            default: null,
        },
        _disableSearch: {
            type: Boolean,
            default: false,
        },
        _paginate: {
            type: Boolean,
            default: false,
        },
        _hideHeader: {
            type: Boolean,
            default: false,
        },
        _serverItemsLength: {
            type: Number,
            default: -1
        },
        _tableStriped: {
            type: Boolean,
            default: true,
        },
        /**
         * Hide inline filters and show all they in sidebar
         * @type {Boolean}
         * @default false
         */
        _hideInlineFilters: {
            type: Boolean,
            default: false,
        },
        /**
         * Put selected items on top of table
         * @type {Boolean}
         * @default false
         */
        _selectedItemsOnTop: {
            type: Boolean,
            default: false,
        },
        /**
         * Hold the selectedItems in the dataprovider
         * @type {Boolean}
         * @default false
         */
        _holdSelectedItems: {
            type: Boolean,
            default: false,
        },
        /**
         * Number of inline filters
         * @type {Number}
         * @default 3
         */
        _inlineFieldsSize: {
            type: Number,
            default: 3,
        },
        /**
         * Show counter of selected items
         * @type {Boolean}
         * @default false
         */
        _showSelectedItemsCounter: {
            type: Boolean,
            default: false,
        },
        /**
         * Export options
         * @type {Boolean}
         * @default false
         */
        _exportOptions: {
            type: Object,
            default: () => ({
                useCommand: false,
            }),
        },
        /**
         * Disable some options of menu
         * @type {string[]}
         */
        _disableOptions: {
            type: Array,
            default: () => []
        },
        /**
         * Preferences key
         * @type {string[]}
         */
         _preferenceKey: {
            type: String,
            default: undefined
        },
        /**
         * Props to pass in menu
         * @type {object}
         */
         _menuProps: {
            type: Object,
            default: () => ({})
        },
        _disableDefaultViewInitialLoading: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            searchLazy: this.search,
            footerProps: {
                'items-per-page-options': [10, 30, 50, -1],
            },
            optionsSync: this.$attrs.options || {
                page: this.$attrs.page || 1,
                itemsPerPage: this.$attrs['items-per-page'] || 15,
            },
            rowsPerPageOptions: [-1, 15, 30, 50, 100],
            tableMode: true,
            attrsValues: {},
            paginationInfo: null,
            serverItemsLength: this._paginate ? Number.MAX_SAFE_INTEGER : -1,
            loading: false,
            itemsChange: false,
            command: this._command,
            internalItemsProvider: [],
            showSelectedItemsCounter: this._showSelectedItemsCounter,
            showMenu: false,
            // menu options
            advancedFilters: {},
            visibleHeaders: [],
            advancedFilters: {},
            dense: true,
        };
    },
    created: function () {
        this.$emit('update:options', this.optionsSync);
        if (this._command && this._paginate) {
            this._command.paginate = true;
            const pageFieldValue = this.$util.getCommandPageField(this._command);
            if (pageFieldValue) {
                pageFieldValue.dependsOn = [{
                    fieldName: pageFieldValue.name,
                    handler: this.onChangePageCommand,
                }]
            }
            this.paginate();
        }
    },
    mounted: function () {
        this.load();
    },
    computed: {
        itemsProvider: {
            get: function () {
                const items = this.internalItemsProvider;

                const filters = Object.entries(this.advancedFilters || {})
                    .filter(([_, value]) => value && value !== '')
                    .map(([key, value]) => (item) => this.$lodash.toLower(item[key]).includes(this.$lodash.toLower(value)))


                if (!this._selectedItemsOnTop) {
                    return items.filter(item => filters.every(filter => filter(item)));
                }

                const itemsNotSelected = items
                    .filter(item => !this.selectedItems.some(i => i[this.itemKey] === item[this.itemKey]))
                    .filter(item => filters.every(filter => filter(item)));

                return this.selectedItems.concat(itemsNotSelected);
            },
            set: function (value) {
                if (this.items) {
                    this.$emit('update:items', value);
                }

                if(!this._holdSelectedItems) {
                    this.selectedItems = [];
                }

                this.internalItemsProvider = value;
            },
        },
        selectedItems: {
            get: function () {
                return this.value;
            },
            set: function (value) {
                this.$emit('input', value);
            },
        },
        isMobile: function () {
            return this.$vuetify.breakpoint.name === 'xs';
        },
        attrsComp: function () {
            return { ...this.$attrs, ...this.attrsValues };
        },
        searchComp: {
            set: function (value) {
                if (!this._disableSearch) {
                    this.searchLazy = value;
                }
            },
            get: function () {
                return !this._disableSearch ? this.searchLazy : null;
            }
        },
        disablePrevious: function () {
            return this.loading ||this.optionsSync.page === 1 || !this.paginationInfo || !this.paginationInfo.itemsLength;
        },
        disableNext: function () {
            if (this._paginate) {
                return this.loading || (this.paginationInfo && (this.itemsProvider.length < this.paginationInfo.itemsPerPage || this.paginationInfo.itemsPerPage < 1));
            } else {
                return this.loading || !this.paginationInfo || this.optionsSync.page === this.paginationInfo.pageCount || !this.paginationInfo.itemsLength;
            }
        },
        showInlineFilters: function () {
            if (this.isMobile) {
                return false;
            }

            return !this._hideInlineFilters;

        }
    },
    watch: {
        // This is very bad and lost a lot of performance with large arrays, remove in the future
        items: {
            deep: true,
            immediate: true,
            handler: function (value, oldValue) {
                if (!this.$lodash.isEqual(value, oldValue) || value !== oldValue) {
                    this.itemsProvider = value;
                }
            },
        },
        '$attrs.options': {
            deep: true,
            handler: function (value, oldValue) {
                if (!this.$lodash.isEqual(value, oldValue)) {
                    this.optionsSync = value || {
                        page: this.$attrs.page || 1,
                        itemsPerPage: this.$attrs.itemsPerPage || 15,
                    };
                }
            },
        },
        optionsSync: {
            deep: true,
            handler: function (value) {
                this.$emit('update:options', value);
            },
        },
        'optionsSync.page': {
            handler: function () {
                if (this.itemsChange) {
                    this.itemsChange = false;
                } else {
                    this.paginate(true, true);
                }
            },
        },
        'optionsSync.itemsPerPage': {
            handler: function (val) {
                if (Number.isNaN(Number(val))) {
                    this.optionsSync.itemsPerPage = this.rowsPerPageOptions.filter(value => value !== -1).sort((a, b) => {
                        if (a > b) {
                            return 1;
                        }
                        if (a < b) {
                            return -1;
                        }
                        return 0;
                    })[0];
                    return;
                }
                val = Number(val);
                if (Number(val) < -1 || Number(val) === 0) {
                    this.optionsSync.itemsPerPage = 1;
                } else if (!this._paginate && this.itemsProvider.length && Number(val) > this.itemsProvider.length) {
                    this.optionsSync.itemsPerPage = Number(this.itemsProvider.length);
                } else if (!Number.isInteger(Number(val))) {
                    this.optionsSync.itemsPerPage = Number(parseInt(val, 10));
                } else {
                    this.optionsSync.itemsPerPage = Number(val);
                }
                const component = this.$lodash.get(this.$refs, 'itemsPerPageCombobox.$refs.combobox');
                if (component && component.isMenuActive) {
                    component.isMenuActive = false;
                }
                if (this._command && this._paginate) {
                    this.itemsChange = true;
                    this.optionsSync.page = 1;
                }
                this.paginate(true);
            },
        },
        search: {
            handler: function (val, oldVal) {
                if (!this.$lodash.isEqual(val, oldVal)) {
                    this.searchLazy = val;
                }
            }
        },
        searchLazy: {
            handler: function (val, oldVal) {
                if (!this.$lodash.isEqual(val, oldVal)) {
                    this.$emit('update:search', val);
                }
            }
        },
        '$attrs.loading': {
            handler: function (val, oldVal) {
                if (!this.$lodash.isEqual(val, oldVal)) {
                    this.loading = val;
                }
            },
        },
        loading: function (val) {
            this.$emit('update:loading', val);
        },
        _command: function (value) {
            this.command = value;
            this.$forceUpdate();
        },
    },
    methods: {
        load: function () {
            this.dense = this._dense;
        },
        toggleTableReports: function () {
            this.tableMode = !this.tableMode;
        },
        toggleMenu: function () {
            this.showMenu = !this.showMenu;
        },
        updateValues: function (values) {
            if (values == null || values.length === 0) {
                this.$_aura.showInfoMessage(this.$t('NO_DATA_FROM_QUERY'));
            }
            this.itemsProvider = values;
            this.$emit('request-provider', values);
        },
        rowsPerPageFilter: function (item, queryText, itemText) {
            if (item.header) return false;
            const hasValue = (val) => (val != null ? val : '');
            const text = hasValue(itemText);
            const query = hasValue(queryText);
            return text.toString()
                .toLowerCase()
                .indexOf(query.toString().toLowerCase()) > -1;
        },
        refreshData: async function (noResetPage) {
            if (this.$refs.filter) {
                await this.$refs.filter.refresh(null, noResetPage);
            }
        },
        pagination: function (event) {
            this.paginationInfo = event;
            this.$emit('pagination', event);
        },
        paginate: async function (refresh, noResetPage) {
            if (this._command && this._paginate) {
                this.itemsChange = false;
                this.$util.loadCommandFilter(this._command, [{ key: 'PAGE', value: this.optionsSync.page - 1 }, { key: 'PAGE_SIZE', value: Number(this.optionsSync.itemsPerPage) >= 0 ? this.optionsSync.itemsPerPage : null }]);
                if (refresh) {
                    this.loading = true;
                    try {
                        await this.refreshData(noResetPage);
                    } finally {
                        this.loading = false;
                    }
                }
            }
        },
        onChangePageCommand: function () {
            const field = this.$util.getCommandPageField(this._command);
            const pageFieldValue = field && field.value;
            if (pageFieldValue != null && (pageFieldValue + 1) !== this.optionsSync.page) {
                this.itemsChange = true;
                this.optionsSync.page = (pageFieldValue + 1);
            }
        },
        clickRow: function (item) {
            this.$emit('click:row', item)
        },
    },
};

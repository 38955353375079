import paths from '@/api/paths';
import cloneDeep from 'lodash/cloneDeep';

const coreMenu = [
    {
      title: 'ANALYSIS',
      icon: 'bar_chart',
      code: 'analysis',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
      ],
    },
    {
      title: 'ASSET_MENU',
      icon: 'mdi-map-marker',
      code: 'asset',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
        title: 'SHOPPING',
        icon: 'mdi-cart',
        code: 'shopping',
        items: [],
        categories: [
            {
                code: 'configurations',
                title: 'CONFIGURATIONS',
            },
            {
              code: 'reports',
              title: 'REPORTS',
            },
        ],
    },
    {
        title: 'CONFIGURATIONS',
        icon: 'mdi-pencil-plus',
        code: 'registration',
        items: [],
        categories: [],
    },
    {
      title: 'CONTRACTS',
      icon: 'fa fa-file-contract',
      code: 'contracts',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'CUSTOMIZATION',
      icon: 'mdi-palette-outline',
      code: 'customization',
      items: [],
      categories: [],
    },
    {
      title: 'STOCK',
      icon: 'fas fa-boxes',
      code: 'stock',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'FINANCIAL',
      icon: 'fas fa-cash-register',
      code: 'financial',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'FLEET',
      icon: 'mdi-truck',
      code: 'fleet',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
      ],
    },
    {
      title: 'MAINTENANCE',
      icon: 'fa fa-hard-hat',
      code: 'maintenance',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'CONSTRUCTIONS',
      icon: 'fa fa-hard-hat',
      code: 'construction',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'BUDGETS',
      icon: 'local_atm',
      code: 'budgets',
      items: [],
      categories: [
        {
          code: 'configurations',
          title: 'CONFIGURATIONS',
        },
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
      title: 'PROCESSES',
      icon: 'fas fa-project-diagram',
      code: 'processes',
      items: [],
      categories: [
        {
          code: 'reports',
          title: 'REPORTS',
        },
      ],
    },
    {
        title: 'RH',
        icon: 'face',
        code: 'humanResources',
        items: [],
        categories: [
            {
                code: 'configurations',
                title: 'CONFIGURATIONS',
            },
        ],
    },
    {
      title: 'SECURITY',
      icon: 'mdi-security',
      code: 'security',
      items: [],
      categories: [],
    },
    {
        title: 'TICKETS',
        icon: 'mdi-message-alert-outline',
        code: 'ticket',
        items: [],
        categories: [
          {
            code: 'configurations',
            title: 'CONFIGURATIONS',
          },
          {
            code: 'reports',
            title: 'REPORTS',
          },
        ],
    },
    {
        title: 'REMOTE_MANAGEMENT',
        icon: 'settings_remote',
        code: 'remoteManagement',
        items: [],
        categories: [],
    },
];


const menu = cloneDeep(coreMenu);


const registrationMenu = menu.find(item => item.code === 'registration');
paths.forEach(path => {
    if (!path.menu) {
        return;
    }

    let opt = menu.find(item => item.code === path.menu);

    if (!opt) {
        opt = registrationMenu;
    }

    opt.items.push({
        title: path.title,
        component: path.name,
        icon: path.icon || opt.icon,
        code: path.name,
        menu: path.menu,
        categories: path.categories,
        props: path.props,
        domains: path.domains,
        allowed: path.allowed,
    });
});

export default menu;

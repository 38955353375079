export const REPORT_TYPES = {
    PIVOT: 'PIVOT',
    SECTION: 'SECTION',
    INDICATOR: 'INDICATOR',
    CUSTOM_MAP: 'CUSTOM_MAP',
    COMPONENT: 'COMPONENT',
    EDITOR: 'EDITOR',
    CUSTOM_REPORT: 'CUSTOM_REPORT',
    CONSOLIDATED_REPORT: 'CONSOLIDATED_REPORT',
};
export const USER_PREFERENCES_KEY = {
    CUSTOM_REPORT_FILTER: 'CUSTOM_REPORT_FILTER',
    PIVOT_REPORT_FILTER: 'PIVOT_REPORT_FILTER',
    ITEM_COMPONENT_FILTER: 'ITEM_COMPONENT_FILTER',
    GLOBAL_FILTER: 'GLOBAL_FILTER',
};
export default {
    REPORT_TYPES: REPORT_TYPES,
};

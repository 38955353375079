<template>
    <a-data-table
        ref='dataTable'
        v-bind='$attrs'
        :items='items'
        :headers='headers'
        :item-key='_itemKey'
        :show-expand='false'
        :show-select='false'
        :search.sync='searchLazy'
        :custom-sort='customSort'
        :_page.sync='page'
        :_items-per-page.sync='itemsPerPage'
        :options.sync='optionsLazy'
        _disable-search
        v-on='listenersComp'
        @click:row='{}'
        @input='{}'
        @item-expanded='{}'
        @item-selected='{}'
        @toggle-select-all='{}'
        @request-provider='requestProvider'
    >
        <template v-for='slot in Object.keys($scopedSlots)' :slot='slot' slot-scope='scope'>
            <slot :name='slot' v-bind='scope' />
        </template>
        <template v-slot:header.data-table-select>
            <a-checkbox
                v-if='!_singleSelect'
                hide-details
                class='ma-0 pa-0'
                :input-value='allSelected'
                :indeterminate='indeterminateSelected'
                @change='changeSelectAll' />
        </template>
        <template v-slot:append-title='props'>
            <slot name='append-title' v-bind='props' />
            <a-slide-group v-if='props.items && props.items.length && itemsBreadcrumb(props.items[0]._parent).length' show-arrows>
                <a-slide-item v-for='(item, index) in itemsBreadcrumb(props.items[0]._parent).reverse()' :key='item[_itemKey]'>
                    <div>
                        <a-icon v-if='index !== 0' default :_tooltip='false' _class-icon='mx-1'>
                            fas fa-chevron-right
                        </a-icon>
                        <a-tooltip bottom>
                            <template v-slot:activator='{ on }'>
                                <span class='cursor-pointer' v-on='on' @click='expandGoTo(item)'>{{item[_breadcrumbKey]}}</span>
                            </template>
                            <span>{{$t('HIDE')}}</span>
                        </a-tooltip>
                    </div>
                </a-slide-item>
            </a-slide-group>
        </template>
        <template v-slot:item='props'>
            <tr @click='$emit("click:row", props.item)' :key='props.item[_itemKey]' :class='props.item.bgColor && _itemClass ? _itemClass(props.item) : ``'>
                <td v-for='(header, index) in props.headers' :key='index' :class='getHeaderClass(header)'>
                    <div class='d-flex flex-row' :style='header.value === firstHeaderData && !_disableIndent ? getStyleFirstCell(props.item._levelTree || 0, props.item[_childrenKey]) : {}'>
                        <template v-if='_showSelect && header.value === "data-table-select" && !props.item[_disableSelectionKey]'>
                            <a-checkbox hide-details class='ma-0 pa-0' :input-value='isSelected(props.item)' @change='itemSelected(props.item, $event)' />
                        </template>
                        <template v-if='header.value === "data-table-expand" || header.prependExpand'>
                            <div class='d-flex flex-row shrink'>
                                <a-icon
                                    v-if='props.item[_childrenKey]'
                                    :expandbottom='!isExpand(props.item)'
                                    :expandtop='isExpand(props.item)'
                                    :_tooltip-text='isExpand(props.item) ? $t("HIDE") : $t("EXPAND")'
                                    _class-icon='icon-width shrink'
                                    :color='_colorIconExpand'
                                    @click='expandItem(props.item)'
                                />
                            </div>
                        </template>
                        <div v-if='!(_showSelect && header.value === "data-table-select" && !props.item[_disableSelectionKey])' :class='`col-12 pa-0 ${header.prependExpand && hasExpand(props.headers) ? "ml-2" : ""}`'>
                            <template v-if='$scopedSlots[`item.${header.value}`]'>
                                <slot :name='`item.${header.value}`' :item='props.item' :header='header' :value='props.item[header.value]' :parent='props.item._parent' />
                            </template>
                            <template v-else>
                                {{props.item[header.value]}}
                            </template>
                        </div>
                    </div>
                </td>
            </tr>
            <td v-if='loading[props.item[_itemKey]]' :colspan='props.headers.length' class='pa-0 td-min-content'>
                <a-progress-linear color='primary' indeterminate />
            </td>
        </template>
    </a-data-table>
</template>

<script>
    import stringSimilarity from 'string-similarity';

    export default {
        name: 'ATreeTable',
        model: {
            prop: '_value',
            event: 'selected-tree-table',
        },
        props: {
            /**
             * Name of key
             * @type {String}
             * @default {'value'}
             */
            _itemKey: {
                type: String,
                default: 'value',
            },
            /**
             * Key name of node children
             * @type {String}
             * @default {'children'}
             */
            _childrenKey: {
                type: String,
                default: 'children',
            },
            /**
             * Key name of item prop for disable selection
             * @type {String}
             * @default {'disableSelection'}
             */
            _disableSelectionKey: {
                type: String,
                default: 'disableSelection',
            },
            /**
             * Disable indent of child
             * @type {Boolean}
             * @default {false}
             */
            _disableIndent: {
                type: Boolean,
                default: false,
            },
            /**
             * Enable selection mode
             * @type {Boolean}
             * @default {false}
             */
            _showSelect: {
                type: Boolean,
                default: false,
            },
            /**
             * Enable single selection mode
             * @type {Boolean}
             * @default {false}
             */
            _singleSelect: {
                type: Boolean,
                default: false,
            },
            /**
             * Array of items expanded (Can be used with .sync modifier)
             * @type {Array}
             * @default {[]}
             */
            _expanded: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            /**
             * Array of selected
             * @type {Array}
             * @default {[]}
             */
            _value: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            /**
             * Key name of breadcrumb description
             * @type {String}
             * @default {'breadcrumb'}
             */
            _breadcrumbKey: {
                type: String,
                default: 'breadcrumb',
            },
            /**
             * Page current (Can be used with .sync modifier)
             * @type {Number}
             * @default {undefined}
             */
            _page: {
                type: Number,
                default: undefined,
            },
            /**
             * Items per page current (Can be used with .sync modifier)
             * @type {Number}
             * @default {undefined}
             */
            _itemsPerPage: {
                type: Number,
                default: undefined,
            },
            /**
             * Table options (Can be used with .sync modifier)
             * @type {Object}
             * @default {undefined}
             */
            options: {
                type: Object,
                default: undefined,
            },
            /**
             * Search field of Table (Can be used with .sync modifier)
             * @type {String}
             * @default {''}
             */
            search: {
                type: String,
                default: '',
            },
            /**
             * Table item class (Need use attribute 'bgColor' on items)
             * @type {Function}
             * @default {null}
             */
            _itemClass: {
                type: Function,
                default: null,
            },
            /**
             *  Color of icon expand
             * @type {String}
             * @default {undefined} 
             */
            _colorIconExpand: {
                type: String,
                default: undefined,
            },
        },
        data: function () {
            return {
                expanded: this._expanded,
                selected: this._value,
                loading: {},
                page: this._page,
                itemsPerPage: this._itemsPerPage,
                itemsLazy: this.$attrs.items || [],
                searchLazy: this.search,
                optionsLazy: this.options,
            };
        },
        computed: {
            allSelected: function () {
                return this.totalSelected === this.allKeys.length;
            },
            indeterminateSelected: function () {
                return this.totalSelected !== 0 && !this.allSelected;
            },
            allSelectableObject: function () {
                return this.getObjectsSelectable(this.itemsLazy);
            },
            allKeys: function () {
                return this.allSelectableObject.map(value => value[this._itemKey]);
            },
            allKeysSelected: function () {
                return this._value.map(value => value[this._itemKey]);
            },
            totalSelected: function () {
                return this.allKeys.reduce((acc, cur) => acc + (this.allKeysSelected.includes(cur) ? 1 : 0), 0);
            },
            headers: function () {
                const headers = this.$attrs.headers ? [...this.$attrs.headers] : [];
                if (this._showSelect && !headers.some(value => value.value === 'data-table-select')) {
                    headers.splice(0, 0, {
                        value: 'data-table-select',
                        align: 'center',
                        width: 1,
                    });
                }
                return headers;
            },
            keyProvider: function () {
                return `${this._childrenKey}Provider`;
            },
            firstHeaderData: function () {
                const header = this.headers.find(value => value.prependExpand) || this.headers.find(value => value.value !== 'data-table-select' && value.value !== 'data-table-expand');
                return header ? header.value : undefined;
            },
            items: function () {
                return this.getObjects(null, this.itemsLazy, 0, true, { sortBy: this.optionsLazy && this.optionsLazy.sortBy, sortDesc: this.optionsLazy && this.optionsLazy.sortDesc }, this.searchLazy);
            },
            listenersComp: function () {
                const value = { ...this.$listeners };
                const keys = Object.keys(value);
                if (keys.includes('update:items')) {
                    delete value['update:items'];
                }
                return value;
            },
            allProcessesExpanded: function () {
                return this.allSelectableObject.filter(obj => obj.CHILDREN).length === this.expanded.length;
            },
        },
        watch: {
            _value: {
                deep: true,
                handler: function (value) {
                    if (!this.$_aura.isEqual(value, this.selected)) {
                        this.selected = value;
                    }
                },
            },
            selected: {
                deep: true,
                handler: function (value) {
                    if (!this.$_aura.isEqual(value, this._value)) {
                        this.$emit('selected-tree-table', value);
                    }
                },
            },
            _expanded: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.expanded = value;
                    }
                },
            },
            expanded: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:_expanded', value);
                    }
                },
            },
            _page: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.page = value;
                    }
                },
            },
            page: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:_page', value);
                    }
                },
            },
            _itemsPerPage: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.itemsPerPage = value;
                    }
                },
            },
            itemsPerPage: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:_itemsPerPage', value);
                    }
                },
            },
            '$attrs.items': {
                handler: function (value, oldValue) {
                    if (!this.$lodash.isEqual(value, oldValue)) {
                        this.itemsLazy = value;
                    }
                }
            },
            options: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.optionsLazy = value;
                    }
                },
            },
            optionsLazy: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:options', value);
                    }
                },
            },
            search: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.searchLazy = value;
                    }
                },
            },
            searchLazy: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:search', value);
                    }
                },
            },
            items: {
                deep: true,
                handler: function () {
                    this.validateExpanded();
                },
            }
        },
        methods: {
            getObjects: function (item, provider, level, onlyExpanded, sort, search) {
                const keys = [];
                if (sort && sort.sortBy && sort.sortBy.length) {
                    provider = this.$_aura.orderBy(provider, sort.sortBy, sort.sortDesc.map(value => (value ? 'desc' : 'asc')));
                }
                provider.forEach(value => {
                    value._parent = item;
                    value.parentItem = item;
                    value._levelTree = level;
                    const children = [];
                    if (value[this._childrenKey] && (!onlyExpanded || search || this.isExpand(value))) {
                        if (Array.isArray(value[this._childrenKey])) {
                            children.push(...this.getObjects(value, value[this._childrenKey], level + 1, onlyExpanded, sort, search));
                        } else if (typeof value[this._childrenKey] === 'function' && value[this.keyProvider]) {
                            children.push(...this.getObjects(value, value[this.keyProvider], level + 1, onlyExpanded, sort, search));
                        }
                    }
                    if (!search || children.length) {
                        keys.push(value);
                        if (!onlyExpanded || this.isExpand(value)) {
                            keys.push(...children);
                        }
                    } else if (this.filterItemByHeaders(value, search)) {
                        keys.push(value);
                    }
                });
                return keys;
            },
            filterItemByHeaders: function (item, search) {
                return this.headers.some(header => {
                    if (header.value == null || (header.filterable != null && !header.filterable)) {
                        return false;
                    }
                    if (header.filter) {
                        return header.filter(item[header.value], search, item);
                    } else if (item[header.value] == null) {
                        return false;
                    }

                    const itemValue = String(item[header.value]);
                    return this.isSimilar(itemValue, search);
                });
            },
            isSimilar: function (string, string2) {
                const stringSanitized = this.accentFold(string.toLowerCase());
                const string2Sanitized = this.accentFold(string2.toLowerCase());
                const similar = stringSimilarity.compareTwoStrings(stringSanitized, string2Sanitized) >= 0.6;
                const includes = stringSanitized.includes(string2Sanitized);

                return includes || similar;
            },
            accentFold: function (str) {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            },
            getObjectsSelectable: function (provider) {
                return this.getObjects(null, provider, 0).filter(value => !value[this._disableSelectionKey]);
            },
            changeSelectAll: function (value) {
                if (value) {
                    this.selected = this.allSelectableObject;
                } else {
                    this.selected = [];
                }
                this.$emit('toggle-select-all', { value: value });
            },
            getHeaderClass: function (header) {
                let classString = '';
                if (header.align === 'left') {
                    classString += ' text-left';
                }
                if (header.align === 'center') {
                    classString += ' text-center';
                }
                if (header.align === 'right') {
                    classString += ' text-right';
                }
                return classString;
            },
            getStyleFirstCell: function (level, hasExpand) {
                return { 'padding-left': `${(level + (!hasExpand ? 1 : 0)) * 16}px` };
            },
            itemSelected: function (item, value) {
                const index = this.selected.findIndex(element => element[this._itemKey] === item[this._itemKey]);
                if (value && index === -1) {
                    delete item._parent;
                    if (this._singleSelect) {
                        this.selected = [item];
                    } else {
                        this.selected.push(item);
                    }
                } else if (!value && index > -1) {
                    if (this._singleSelect) {
                        this.selected = [];
                    } else {
                        this.selected.splice(index, 1);
                    }
                }
                this.$emit('item-selected', { item: item, value: index === -1, parent: item._parent });
            },
            isSelected: function (item) {
                if (item[this._itemKey]) {
                    return this.selected.findIndex(element => element[this._itemKey] === item[this._itemKey]) > -1;
                }
                return false;
            },
            expandItem: function (item) {
                if (item[this._itemKey]) {
                    const index = this.expanded.findIndex(element => element === item[this._itemKey]);
                    if (index === -1) {
                        this.expanded.push(item[this._itemKey]);
                        if (typeof item[this._childrenKey] === 'function') {
                            item[this.keyProvider] = [];
                            this.loading[item[this._itemKey]] = true;
                            item[this._childrenKey](item)
                                .then(
                                    resolve => {
                                        item[this.keyProvider] = resolve;
                                        this.expanded = [...this.expanded];
                                        delete this.loading[item[this._itemKey]];
                                    },
                                    () => {
                                        delete this.loading[item[this._itemKey]];
                                    },
                                );
                        }
                    } else if (index > -1) {
                        this.expanded.splice(index, 1);
                        if (Array.isArray(item[this._childrenKey])) {
                            this.hideChilds(item[this._childrenKey]);
                        } else if (typeof item[this._childrenKey] === 'function' && item[this.keyProvider]) {
                            this.hideChilds(item[this.keyProvider]);
                        }
                    }
                    this.$emit('update:_expanded', this.expanded);
                    this.$emit('item-expanded', { item: item, value: index === -1, parent: item._parent });
                }
            },
            hideChilds: function (children) {
                if (children && Array.isArray(children)) {
                    children.forEach(value => {
                        const index = this.expanded.findIndex(element => element === value[this._itemKey]);
                        if (index > -1) {
                            this.expanded.splice(index, 1);
                        }
                        if (Array.isArray(value[this._childrenKey])) {
                            this.hideChilds(value[this._childrenKey]);
                        } else if (typeof value[this._childrenKey] === 'function' && value[this.keyProvider]) {
                            this.hideChilds(value[this.keyProvider]);
                        }
                    });
                }
            },
            isExpand: function (item) {
                if (item[this._itemKey]) {
                    return this.expanded.findIndex(element => element === item[this._itemKey]) > -1;
                }
                return false;
            },
            hasExpand: function (headers) {
                return headers.some(value => value.value === 'data-table-expand' || value.prependExpand);
            },
            customSort: function (items) {
                return items;
            },
            itemsBreadcrumb: function (parent) {
                const breadcrumb = [];
                if (parent) {
                    breadcrumb.push(parent, ...this.itemsBreadcrumb(parent._parent));
                }
                return breadcrumb;
            },
            expandGoTo: function (item) {
                this.expandItem(item);
                this.$nextTick(() => {
                    const index = this.items.findIndex(value => value[this._itemKey] === item[this._itemKey]);
                    if (this.itemsPerPage > 0 && index > -1) {
                        this.page = Number((((index / this.itemsPerPage) + 1) - ((index / this.itemsPerPage) % 1)).toFixed(0));
                    }
                });
            },
            requestProvider: function (provider) {
                this.itemsLazy = provider;
                this.$emit("update:items", provider);
            },
            validateExpanded: function () {
                const newExpanded = this.expanded.filter(key => this.items.find(item => item[this._itemKey] === key));
                if (!this.$lodash.isEqual(newExpanded, this.expanded)) {
                    this.expanded = newExpanded;
                }
            },
            refreshData: function (refreshValue) {
                this.$refs.dataTable.refreshData(refreshValue);
            },
            expandAllProcesses: function () {
                if (this.allProcessesExpanded) {
                    this.expanded = [];
                    return;
                }
                this.allSelectableObject.forEach(selectableObject => {
                    const instance = this.expanded.find(e => e === selectableObject.ID_INSTANCIA);
                    if (!this.allProcessesExpanded && !instance && selectableObject.CHILDREN) {
                        this.expandItem(selectableObject);
                    }
                });
            },
        },
    };
</script>

<style lang='stylus' scoped>
    >>>.icon-width
        width 16px !important
    .td-min-content
        height min-content !important
    .cursor-pointer
        cursor pointer
</style>

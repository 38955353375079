import Vue from 'vue';
import Vuetify from 'vuetify';
import pt from 'vuetify/lib/locale/pt';
import AuraVue from '@/lib/aura-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import _ from 'lodash';
import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import theme from '@/style/theme';
import AuraAutoCompletes from '@/util/inputs/autocomplete';
import AutocompleteEditDialogs from '@/util/inputs/autocomplete/util/editDialog';
import AuraSelects from '@/util/inputs/select';
import SelectEditDialogs from '@/util/inputs/select/util/editDialog';
import AuraComboboxs from '@/util/inputs/combobox';
import ComboboxEditDialogs from '@/util/inputs/combobox/util/editDialog';
import PivotTable from '@/components/core/pivot/pivot';
import DataTableReport from '@/components/core/dataTableReport/DataTableReport.vue';
import LeafletDrawLocate from '@/util/leafletDrawLocate/leaflet-draw-locate';
import { PageFunctionsMixin } from '@/mixins/page-functions';
import util from './util/util';
import App from './App.vue';
import router from './router';
import store from './store/store';
import './plugins';
import i18n from './i18n';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
moment.locale('pt-BR');
momentDurationFormatSetup(moment);
Vue.config.productionTip = false;
Vue.use(VeeValidate);
Vue.use(VueSweetalert2);
Vue.use(VueTheMask);
Vue.use(PivotTable);
const notification = {
    closeButton: true,
    tapToDismiss: false,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-bottom-left',
    preventDuplicates: true,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '99999999999999',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
};
const colors = {
    ...theme.light,
};
/* Vuetify configurations */
const vuetify = new Vuetify({
    theme: {
        themes: theme,
        options: { customProperties: true, variations: false },
    },
    lang: {
        locales: { pt: pt },
        current: 'pt',
    },
});
Vue.use(Vuetify);
/* Aura configurations */
let debug = process.env.NODE_ENV === 'development' || process.env.VUE_APP_MOCK;
let debugURL = process.env.VUE_APP_REQUEST_BASE_URL;
const prodURL = `${window.location.origin}/guia/command/${window.location.host.split('.')[0]}`;
if (!debug) {
    debug = true;
    debugURL = prodURL;
}
const finalDebugURL = process.env.VUE_APP_MOCK ? process.env.VUE_APP_MOCK_REQUEST_BASE_URL : debugURL;
Vue.use(AuraVue, {
    theme: {
        color: colors,
        image: {
            emptyData: require('@/assets/images/emptyData.png').default,
            searchData: require('@/assets/images/searchData.svg').default,
            loginBackground: '',
            'empty-reports': require('@/assets/images/empty-reports.png').default,
        },
    },
    request: {
        url: prodURL,
        debug: {
            actived: debug,
            url: finalDebugURL,
        },
    },
    inputs: {
        autocomplete: AuraAutoCompletes,
        select: AuraSelects,
        combobox: AuraComboboxs,
        editDialog: {
            autocomplete: AutocompleteEditDialogs,
            select: SelectEditDialogs,
            combobox: ComboboxEditDialogs,
            util: {
                openPage: function (page, config) {
                    return util.openPage(page, config);
                },
            },
        },
    },
    image: {
        'citizen-issue-app': 'ConsultarIconeTpOcorrenciaCidadaoParque',
        worksiteImage: 'ConsultarImagemParqueServico',
        worksiteSplash: 'ConsultarSplashParqueServicoConfigAppCidadao',
        citizenRequesAppImage: 'ConsultarIconeTpOcorrenciaCidadaoParque',
        'issue-type-img': 'ConsultarIconeTpOcorrencia',
        'ticket-asset-photo-img': 'ConsultarDadosArquivo',
    },
    pivot: {
        component: DataTableReport,
    },
    notification: notification,
});
Vue.prototype.$util = util;
Vue.prototype.$moment = moment;
Vue.prototype.$lodash = _;
Vue.use(LeafletDrawLocate);
// eslint-disable-next-line @typescript-eslint/no-floating-promises
store.dispatch('LOAD_DOMAIN');
const vm = new Vue({
    router: router,
    store: store,
    vuetify: vuetify,
    i18n: i18n,
    data: () => ({ isLoaded: document.readyState === 'complete' }),
    render: function (h) {
        return this.isLoaded ? h(App) : undefined;
    },
}).$mount('#app');
// Prevent layout jump while waiting for styles
// eslint-disable-next-line @typescript-eslint/no-unused-expressions
vm.isLoaded || window.addEventListener('load', () => {
    vm.isLoaded = true;
});
Vue.mixin(PageFunctionsMixin);

/**
 * Nodered
 * =============================================================================
 */
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
import { parseSuccessResponse, showErrorsResponse } from '@/pages/remoteManagement/services/utils';
export class SaveNoderedControl extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_nodered_control',
            type: 'SAVE',
            fields: {
                deviceIds: new Field({
                    name: 'BODY',
                }),
                groupDeviceIds: new Field({
                    name: 'BODY',
                }),
                options: new Field({
                    name: 'BODY',
                }),
                companyId: new Field({
                    name: 'BODY',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const body = {
                    device_ids: params.deviceIds,
                    device_group_ids: params.deviceGroupIds ?? [],
                    company_id: params.companyId,
                    options: params.options,
                };
                return {
                    BODY: JSON.stringify(body),
                    ADMIN_PATH: params.adminPath,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveNoderedStatus extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_nodered_status',
            type: 'SAVE',
            fields: {
                deviceIds: new Field({
                    name: 'BODY',
                }),
                groupDeviceIds: new Field({
                    name: 'BODY',
                }),
                options: new Field({
                    name: 'BODY',
                }),
                companyId: new Field({
                    name: 'BODY',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    device_ids: params.deviceIds,
                    device_group_ids: params.deviceGroupIds ?? [],
                    company_id: params.companyId,
                    options: params.options,
                };
                return {
                    BODY: JSON.stringify(data),
                    ADMIN_PATH: params.adminPath,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveNoderedReset extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_nodered_reset',
            type: 'SAVE',
            fields: {
                deviceIds: new Field({
                    name: 'BODY',
                }),
                groupDeviceIds: new Field({
                    name: 'BODY',
                }),
                options: new Field({
                    name: 'BODY',
                }),
                companyId: new Field({
                    name: 'BODY',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    device_ids: params.deviceIds,
                    device_group_ids: params.deviceGroupIds ?? [],
                    company_id: params.companyId,
                    options: params.options,
                };
                return {
                    BODY: JSON.stringify(data),
                    ADMIN_PATH: params.adminPath,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveNoderedMeasurement extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_nodered_measurement',
            type: 'SAVE',
            fields: {
                deviceIds: new Field({
                    name: 'BODY',
                }),
                deviceGroupIds: new Field({
                    name: 'BODY',
                }),
                companyId: new Field({
                    name: 'BODY',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    device_ids: params.deviceIds,
                    device_group_ids: params.deviceGroupIds ?? [],
                    company_id: params.companyId,
                };
                return {
                    BODY: JSON.stringify(data),
                    ADMIN_PATH: params.adminPath,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}
export class SaveNoderedAlert extends CallCommand {
    constructor() {
        super({
            name: 'post_comp_nodered_alert',
            type: 'SAVE',
            fields: {
                deviceIds: new Field({
                    name: 'BODY',
                }),
                deviceGroupIds: new Field({
                    name: 'BODY',
                }),
                companyId: new Field({
                    name: 'BODY',
                }),
                adminPath: new Field({
                    name: 'ADMIN_PATH',
                    value: true,
                }),
            },
            customRequestParser: function (params) {
                const data = {
                    device_ids: params.deviceIds,
                    device_group_ids: params.deviceGroupIds ?? [],
                    company_id: params.companyId,
                };
                return {
                    BODY: JSON.stringify(data),
                    ADMIN_PATH: params.adminPath,
                };
            },
        });
    }
    async onSuccess(resolve) {
        return parseSuccessResponse(resolve);
    }
    onError(reject) {
        return showErrorsResponse(reject);
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, { silent: true, ...config }, debug);
    }
}

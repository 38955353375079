import Vue from 'vue';
import Vuex from 'vuex';
// import createPlugin from 'logrocket-vuex';
// import LogRocket from 'logrocket';
import customWindow from './modules/custom-window';
import loading from './modules/loading';
import operationPanel from './modules/operation-panel';
import backgroundReport from './modules/background-report';
import chatLog from './modules/chat-log';
import locale from './modules/locale';
import userModule from './modules/user';
import idleControlModule from './modules/idle-control';
import helpModule from './modules/help';

Vue.use(Vuex);

// const logrocketPlugin = createPlugin(LogRocket);

export default new Vuex.Store({
    modules: {
        customWindow: customWindow,
        loading: loading,
        operationPanel: operationPanel,
        backgroundReport: backgroundReport,
        chatLog: chatLog,
        user: userModule,
        locale: locale,
        timeout: idleControlModule,
        help: helpModule,
    },
    state: {
        theme: { dark: false },
        idWorksite: null,
        domain: [],
        loginStyleName: null,
        enableLateralMenu: false,
        isLateralMenuOpen: true,
        currency: {
            code: 'BRL',
            locale: 'Pt-Br',
            symbol: 'R$',
        },
        reloadDomain: false,
    },
    getters: {
        getEnableLateralMenu: state => state.enableLateralMenu,
        getIsLateralMenuOpen: state => state.isLateralMenuOpen,
        getDomainUser: state => state.domain,
        getDomain: (state, getters) => {
            const user = getters.getUser;

            if (user && user.personId) {
                const domain = state.domain.find(d => d.userId === user.personId);
                return domain && domain.domainObject ? domain.domainObject : {};
            }

            return {};
        },
        getDomainIds: (state, getters) => {
            const user = getters.getUser;

            if (user && user.personId) {
                const domain = state.domain.find(value => value.userId === user.personId) || [];
                return domain.domainObject ? domain.domainObject.ID_DOMINIO : [];
            }

            return [];
        },
        getLoginStyleName: state => state.loginStyleName,
        getCurrencySymbol: state => state.currency.symbol,
        getCurrency: state => state.currency,
        getReloadDomain: state => state.reloadDomain,
    },
    mutations: {
        SET_THEME: function (state, theme) {
            state.theme = theme;
        },
        CHANGE_THEME_STYLE: function (state) {
            state.theme.dark = !state.theme.dark;
        },
        SET_WORKSITE: function (state, idWorksite) {
            state.idWorksite = idWorksite;
        },
        SET_DOMAIN: function (state, value) {
            const { user } = state.user;
            if (user && user.personId) {
                const domain = state.domain.find(element => element.userId === user.personId);
                if (domain) {
                    domain.domainObject = value || [];
                } else {
                    state.domain.push({
                        userId: user.personId,
                        domainObject: value || [],
                    });
                }
                const domainStorage = [];
                state.domain.forEach(element => {
                    const obj = { ...element };
                    domainStorage.push(obj);
                });
                localStorage.setItem('domain', JSON.stringify(domainStorage));
            }
        },
        SET_DOMAIN_USER: function (state, value) {
            state.domain = value;
            const domainStorage = [];
            state.domain.forEach(element => {
                const obj = { ...element };
                domainStorage.push(obj);
            });
            localStorage.setItem('domain', JSON.stringify(domainStorage));
        },
        SET_LOGIN_STYLE_NAME: function (state, loginStyleName) {
            state.loginStyleName = loginStyleName;
        },
        SET_ENABLE_LATERAL_MENU: function (state, value) {
            state.enableLateralMenu = value;
        },
        SET_LATERAL_MENU_OPEN: function (state, value) {
            state.isLateralMenuOpen = value;
        },
        /** The value parameter accepts an object with {code: str, locale: str, symbol:str} */
        // eslint-disable-next-line no-shadow
        SET_CURRENCY: function (state, { code, locale, symbol }) {
            state.currency.code = code;
            state.currency.locale = locale;
            state.currency.symbol = symbol;
        },
        SET_RELOAD_DOMAINS: function (state) {
            state.reloadDomain = !state.reloadDomain;
        },
    },
    actions: {
        // eslint-disable-next-line no-shadow
        SET_CURRENCY: function ({ commit }, { code, locale, symbol }) {
            commit('SET_CURRENCY', { code: code, locale: locale, symbol: symbol });
        },
        LOAD_DOMAIN: function ({ commit }) {
            let domainsUser = localStorage.getItem('domain');
            let valid = false;
            if (domainsUser) {
                try {
                    domainsUser = JSON.parse(domainsUser);
                    if (domainsUser && Array.isArray(domainsUser)) {
                        const domains = [];
                        domainsUser.forEach(value => {
                            if (value && value.domainObject && value.userId) {
                                domains.push({
                                    userId: value.userId,
                                    domainObject: value.domainObject,
                                });
                            }
                        });
                        commit('SET_DOMAIN_USER', domains);
                        valid = true;
                    }
                } catch (e) {
                    valid = false;
                }
            }
            if (!valid) {
                commit('SET_DOMAIN_USER', []);
            }
        },
        SET_DEFAULT_DOMAIN: function ({ commit }) {
            commit('SET_DOMAIN', {
                COD_DOMINIO: 'GEN',
                ID_DOMINIO: '0',
                DESC_DOMAIN: 'Todos',
                COR_DOMINIO: '#000000',
            });
            commit('SET_RELOAD_DOMAINS');
        },
    },
    // plugins: [logrocketPlugin],
});

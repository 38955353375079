const image = {
    options: {},
    initializeOptions: function (options) {
        // Locale -> String with a BCP 47 language tag (nessário pelo moment.js)
        // dateFormat/ dateTimeFormat -> Formato padrão das datas retornadas pelo JAVA
        const aux = {
            person: 'ConsultarFotoPessoa',
        };
        this.options = Object.assign({}, aux, options);
    },
    get: function (name) {
        return this.options[name] || null;
    },
};

export default image;

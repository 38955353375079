const paths = [
    {
        path: '/work-diary',
        title: 'WORK_DIARY',
        name: 'WorkDiary',
        icon: 'fa fa-briefcase',
        menu: 'analysis',
        component: () => import(/* webpackChunkName: "workDiary" */ /* webpackMode: "lazy" */ '@/pages/workDiary/WorkDiary.vue'),
    },
    {
        path: '/work-diary-equipment-allocation',
        title: 'EQUIPMENT_ALLOCATION',
        name: 'WorkDiaryEquipmentAllocation',
        component: () => import(/* webpackChunkName: "workDiary" */ /* webpackMode: "lazy" */ '@/pages/workDiary/WorkDiaryEquipmentAllocation.vue'),
    },
    {
        path: '/work-diary-staff-allocation',
        title: 'STAFF_ALLOCATION',
        name: 'WorkDiaryStaffAllocation',
        component: () => import(/* webpackChunkName: "workDiary" */ /* webpackMode: "lazy" */ '@/pages/workDiary/WorkDiaryStaffAllocation.vue'),
    },
];

export default paths;

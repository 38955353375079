<template>
    <a-row dense='dense'>
        <a-col cols='12' class='overflow-hidden' style='position: relative;'>
            <!--==============================-->
            <!--=========RENDER TABLE=========-->
            <!--==============================-->
            <a-data-table
                v-show='showTable'
                ref='table'
                v-model='value'
                v-bind='tableAttrs'
                :item-key='_itemKey'
                :_command='_command'
                :show-select='_showSelect'
                :items.sync='itemsSync'
                :_hide-default-footer='_hideTableDefaultFooter'
                :options.sync='paginationSync'
                :sort-by.sync='sortBy'
                :sort-desc.sync='sortDesc'
                :_paginate='_paginate'
                @pagination='onPagination'
            >
                <slot v-for='slot in tableSlot' :slot='slot' :name='"table-" + slot' />
                <template v-for='slot in tableSlotScoped' :slot='slot' slot-scope='scope'>
                    <slot :name='"table-" + slot' v-bind='scope' />
                </template>
                <template v-slot:append-title>
                    <slot name='append-title'>
                    </slot>
                </template>
                <template v-slot:info-header>
                    <slot name='info-header'>
                    </slot>
                </template>
                <template v-slot:prepend-search>
                    <a-container class='px-0'>
                        <a-row align='center'>
                            <slot v-if='Object.keys($slots).includes("table-prepend-search")' name='table-prepend-search' />
                            <a-btn-toggle v-model='showTable' class='mx-1' mandatory='mandatory' rounded='rounded'>
                                <a-tooltip bottom='bottom'>
                                    <template v-slot:activator='{ on }'>
                                        <a-btn text='text' small='small'>
                                            <a-icon v-on='on'>
                                                fas fa-th-large
                                            </a-icon>
                                        </a-btn>
                                    </template><span>{{$t('CARD_MODE')}}</span>
                                </a-tooltip>
                                <a-tooltip bottom='bottom'>
                                    <template v-slot:activator='{ on }'>
                                        <a-btn text='text' small='small'>
                                            <a-icon v-on='on'>
                                                fa fa-list-ol
                                            </a-icon>
                                        </a-btn>
                                    </template><span>{{$t('LIST_MODE')}}</span>
                                </a-tooltip>
                            </a-btn-toggle>
                        </a-row>
                    </a-container>
                </template>
            </a-data-table>
            <!--==================================-->
            <!--=========RENDER THUMBNAIL=========-->
            <!--==================================-->
            <a-data-iterator
                v-show='!showTable'
                ref='thumbnail'
                v-model='value'
                v-bind='thumbnailAttrs'
                content-tag='v-layout'
                row='row'
                wrap='wrap'
                :_command='_command'
                :items.sync='itemsSync'
                :item-key='_itemKey'
                :options.sync='paginationSync'
                :sort-by.sync='sortBy'
                :sort-desc.sync='sortDesc'
                :_paginate='_paginate'
                @pagination='onPagination'
            >
                <slot v-for='slot in thumbnailSlot' :slot='slot' :name='"thumbnail-" + slot' />
                <template v-for='slot in thumbnailSlotScoped' :slot='slot' slot-scope='scope'>
                    <slot :name='"thumbnail-" + slot' v-bind='scope' />
                </template>
                <template v-slot:append-title>
                    <slot name='append-title'>
                    </slot>
                </template>
                <template v-slot:info-header>
                    <slot name='info-header'>
                    </slot>
                </template>
                <template v-slot:prepend-search>
                    <a-container class='px-0'>
                        <a-row dense align='center'>
                            <a-col v-if='Object.keys($slots).includes("thumbnail-prepend-search")' cols='auto'>
                                <slot name='thumbnail-prepend-search' />
                            </a-col>
                            <a-col cols='auto'>
                                <a-btn-toggle v-model='showTable' class='mx-1' mandatory='mandatory' rounded='rounded'>
                                    <a-tooltip bottom='bottom'>
                                        <template v-slot:activator='{ on }'>
                                            <a-btn text='text' small='small'>
                                                <a-icon v-on='on'>
                                                    fas fa-th-large
                                                </a-icon>
                                            </a-btn>
                                        </template><span>{{$t('CARD_MODE')}}</span>
                                    </a-tooltip>
                                    <a-tooltip bottom='bottom'>
                                        <template v-slot:activator='{ on }'>
                                            <a-btn text='text' small='small'>
                                                <a-icon v-on='on'>
                                                    fa fa-list-ol
                                                </a-icon>
                                            </a-btn>
                                        </template><span>{{$t('LIST_MODE')}}</span>
                                    </a-tooltip>
                                </a-btn-toggle>
                            </a-col>
                            <a-col v-if='!_paginate' cols='auto'>
                                <a-select
                                    v-if='_showThumbnailDefaultOrder'
                                    v-model='selectOrder'
                                    class='shrink mr-1 select-order-width'
                                    flat='flat'
                                    hide-no-data='hide-no-data'
                                    hide-details='hide-details'
                                    single-line='single-line'
                                    outlined='outlined'
                                    :items='orderProvider'
                                    :label='$t("ORDINATION")'
                                    item-text='text'
                                    item-value='value'
                                    menu-props='offsetY'
                                    :clearable='!thumbnailMultiple'
                                    :multiple='thumbnailMultiple'
                                >
                                    <template v-slot:selection='{ item, index }'>
                                        <span v-if='index === 0'>{{item.text}}</span><span v-if='index === 1' class='grey--text text-caption'>(+{{selectOrder.length - 1}} others)</span>
                                    </template>
                                    <template v-if='thumbnailMultiple' v-slot:item='{ item, attrs }'>
                                        <a-list-item-action>
                                            <a-checkbox v-model='attrs.inputValue' :disabled='attrs.disabled' />
                                        </a-list-item-action>
                                        <a-list-item-title>{{item.text}}</a-list-item-title>
                                        <a-list-item-action v-if='selectOrder.includes(item.value)'>
                                            <a-layout row='row'>
                                                <span :class='`badge-order-sort ${attrs.activeClass}`'>{{getIndexSortDesc(item.value) + 1}}</span>
                                                <a-icon class='ml-2' small='small' @click.stop='handlerSortDesc(item.value)'>
                                                    {{getSortDesc(item.value) ? 'fa fa-arrow-down' : 'fa fa-arrow-up'}}
                                                </a-icon>
                                            </a-layout>
                                        </a-list-item-action>
                                    </template>
                                </a-select>
                            </a-col>
                            <a-col v-if='!_paginate' cols='auto'>
                                <a-tooltip bottom='bottom'>
                                    <template v-slot:activator='{ on }'>
                                        <a-icon v-if='!thumbnailMultiple && _showThumbnailDefaultOrder' class='mx-1' small='small' v-on='on' @click.stop='handlerSortDesc(selectOrder)'>
                                            {{getSortDesc(selectOrder) ? 'fa fa-arrow-down' : 'fa fa-arrow-up'}}
                                        </a-icon>
                                    </template>
                                    <span>{{$t('CHANGE_ORDER')}}</span>
                                </a-tooltip>
                            </a-col>
                        </a-row>
                    </a-container>
                </template>
            </a-data-iterator>
        </a-col>
    </a-row>
</template>

<script>
    import { getBooleanFromProp, getProps } from '../../util/helpers';

    export default {
        name: 'ATableThumbnail',
        model: {
            prop: '_value',
        },
        props: {
            _value: {
                type: Array,
                require: false,
                default: function () {
                    return [];
                },
            },
            renderTable: {
                type: Boolean,
                require: true,
                default: true,
            },
            _itemKey: {
                type: String,
                require: false,
                default: '',
            },
            _showSelect: {
                type: Boolean,
                require: false,
                default: false,
            },
            _hideTableDefaultFooter: {
                type: Boolean,
                require: false,
                default: true,
            },
            _paginate: {
                type: Boolean,
                require: false,
                default: false,
            },
            items: {
                type: Array,
                default: function () {
                    return [];
                },
            },
            _command: {
                type: Object,
                default: function () {
                    return {};
                },
            },
            /**
             * Prop to show thumbnail default order select
             * @type {Boolean}
             * @default {true}
             * @require {false}
             */
            _showThumbnailDefaultOrder: {
                type: Boolean,
                require: false,
                default: true,
            },
        },
        data: function () {
            return {
                showTable: true,
                value: this._value,
                paginationSync: this.$attrs.options || null,
                sortBy: [],
                sortDesc: [],
                itemsSync: this.items || [],
            };
        },
        computed: {
            tableAttrs: function () {
                const key = getProps(getProps(this.$attrs, [['t-']]), [['multi-sort', 'items', '_commands']], false);
                const keyNoTag = getProps(this.$attrs, [['i-', 't-', 'render-table', 'items', '_commands', ...Object.keys(key)]], false);
                return { ...key, ...keyNoTag };
            },
            thumbnailAttrs: function () {
                const key = getProps(getProps(this.$attrs, [['i-']]), [['multi-sort', 'items', '_commands']], false);
                const keyNoTag = getProps(this.$attrs, [['i-', 't-', 'render-table', 'items', '_commands', ...Object.keys(key)]], false);
                return { ...key, ...keyNoTag };
            },
            tableSlot: function () {
                return Object.keys(getProps(getProps(this.$slots, [['table-']]), [['top', 'prepend-search']], false));
            },
            tableSlotScoped: function () {
                return Object.keys(getProps(getProps(this.$scopedSlots, [['table-']]), [['top', 'prepend-search']], false));
            },
            thumbnailSlot: function () {
                return Object.keys(getProps(getProps(this.$slots, [['thumbnail-']]), [['top'], 'prepend-search'], false));
            },
            thumbnailSlotScoped: function () {
                return Object.keys(getProps(getProps(this.$scopedSlots, [['thumbnail-']]), [['top'], 'prepend-search'], false));
            },
            orderProvider: function () {
                let provider = [];
                if (this.$attrs['t-headers'] != null) {
                    provider = this.$attrs['t-headers'];
                } else if (this.$attrs['i-headers'] != null) {
                    provider = this.$attrs['i-headers'];
                } else if (this.$attrs.headers != null) {
                    provider = this.$attrs.headers;
                }
                return provider.filter(value => value.sortable == null || value.sortable);
            },
            selectOrder: {
                set: function (value) {
                    if (this.thumbnailMultiple) {
                        const include = value.filter(item => !this.sortBy.includes(item));
                        const notInclude = this.sortBy.filter(item => !value.includes(item));
                        notInclude.forEach(item => this.sortDesc.splice(this.selectOrder.indexOf(item), 1));
                        this.sortBy = value;
                        include.forEach(item => this.sortDesc.splice(this.selectOrder.indexOf(item), 0, false));
                        this.sortDesc = [...this.sortDesc];
                    } else {
                        this.sortBy = [value];
                        this.sortDesc = [value ? false : undefined];
                    }
                    this.$emit('itemsSorted', value);
                },
                get: function () {
                    if (this.thumbnailMultiple) {
                        return this.sortBy;
                    }
                    return this.sortBy[0];
                },
            },
            thumbnailMultiple: function () {
                return this.thumbnailAttrs['multi-sort'] != null && getBooleanFromProp(this.thumbnailAttrs['multi-sort']);
            },
            isMobile: function () {
                return this.$vuetify.breakpoint.smAndDown;
            }
        },
        watch: {
            value: {
                deep: true,
                handler: function (value) {
                    this.$emit('input', value);
                },
            },
            renderTable: function (value) {
                this.showTable = value ? 1 : 0;
            },
            showTable: function (value) {
                this.showTable = value ? 1 : 0;
                this.$emit('change-tab', this.showTable);
            },
            pagination: {
                sortBy: function (value) {
                    if (value == null) {
                        this.pagination.descending = false;
                    }
                },
            },
            items: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.itemsSync = value;
                    }
                },
            },
            itemsSync: {
                deep: true,
                handler: function (value, oldValue) {
                    if (!this.$_aura.isEqual(value, oldValue)) {
                        this.$emit('update:items', value);
                    }
                },
            },
            sortDesc: {
                deep: true,
                handler: function (value) {
                    if (this.sortBy[0]) {
                        this.$emit('itemsSorted', this.sortBy[0], value[0]);
                    }
                },
            },
        },
        created: function () {
            this.showTable = this.renderTable ? 1 : 0;
        },
        methods: {
            handlerSortDesc: function (value) {
                if (this.selectOrder) {
                    this.sortDesc[this.selectOrder.indexOf(value)] = !this.getSortDesc(value);
                    this.sortDesc = [...this.sortDesc];
                }
            },
            getSortDesc: function (value) {
                return value ? this.sortDesc[this.getIndexSortDesc(value)] : false;
            },
            getIndexSortDesc: function (value) {
                return value ? this.selectOrder.indexOf(value) : -1;
            },
            refreshData: async function () {
                if (this.$refs.filter) {
                    await this.$refs.filter.refresh();
                }
            },
            onPagination: function (event) {
                this.$emit('pagination', event);
            },
        },
    };
</script>

<style lang='stylus' scoped>
    .select-order-width
        width 250px

    .min-height-top
        min-height 78px

    .no-data-alert
        border-color: transparent !important;
        color: primary-color;

    .button-order
        min-width: 0

    .badge-order-sort
        background-color rgba(0,0,0,.12)
        color rgba(0,0,0,.87)
        display inline-flex
        justify-content center
        align-items center
        border 0
        border-radius 50%
        min-width 18px
        min-height 18px
        height 18px
        width 18px
</style>

const paths = [
    {
        path: '/service-order',
        title: 'SERVICE_ORDER',
        name: 'ServiceOrder',
        favCategory: 'maintenance',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrder.vue'),
    },
    {
        path: '/service-order-list',
        title: 'SERVICE_ORDERS',
        name: 'ServiceOrderList',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderList.vue'),
    },
    {
        path: '/report-template-emission',
        title: 'REPORT_EMISSION',
        name: 'ReportTemplateEmission',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ReportTemplateEmission.vue'),
    },
    {
        path: '/service-order-route-generation',
        title: 'GENERATE_ROUTE',
        name: 'ServiceOrderRouteGeneration',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderRouteGeneration.vue'),
    },
    {
        path: '/materials-in-warranty',
        title: 'MATERIALS_IN_WARRANTY',
        name: 'MaterialsInWarranty',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/MaterialsInWarranty.vue'),
    },
    {
        path: '/service-order-photo-template',
        title: 'SERVICE_ORDER_PHOTO_TEMPLATE',
        name: 'ServiceOrderPhotoTemplate',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderPhotoTemplate.vue'),
    },
    {
        path: '/service-order-task-archives',
        title: 'ARCHIVES',
        name: 'ServiceOrderTaskArchives',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderTaskArchives.vue'),
    },
    {
        path: '/service-order-new',
        title: 'SERVICE_ORDER_NEW',
        name: 'ServiceOrderNew',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderNew.vue'),
    },
    {
        path: '/service-order-panel',
        title: 'MONITORING_PANEL',
        name: 'ServiceOrderPanel',
        icon: 'fa fa-hard-hat',
        menu: 'maintenance',
        categories: ['configurations'],
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderPanel.vue'),
    },
    {
        path: '/service-order-preview',
        name: 'ServiceOrderPreview',
        component: () => import(/* webpackChunkName: "serviceOrder" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderPreview.vue'),
    },
    {
        path: '/service-order-edit-history',
        name: 'ServiceOrderEditHistory',
        title: '/SERVICE_ORDER_EDIT_HISTORY',
        component: () => import(/* webpackChunkName: "maintenance" */ /* webpackMode: "lazy" */ '@/pages/serviceOrder/ServiceOrderEditHistory.vue'),
    },
];

export default paths;
